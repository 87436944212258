import axios from "axios"
import { useState } from "react"
import { Link } from "react-router-dom"
import "./register.css"

export default function Register() {
    const [name, setUsername] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [error, setError] = useState(false)
    const [disabled, setDisabled] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (disabled) {
            return;
        }
        setDisabled(true);
        setError(false)
        try {
            const res = await axios.post("/api/auth/register", {name, email, password})
            res.data && window.location.replace("/login")
        } catch(err) {
            setError(true)
        }
        setDisabled(false)
    }
    return (
        <div className="register">
            <span className="registerTitle">Регистрация</span>
            <form className="registerForm" onSubmit={handleSubmit}>
                <label>Имя пользователя</label>
                <input type="text" className="registerInput" placeholder="Введите имя пользователя..." onChange={e=>setUsername(e.target.value)} />
                <label>Электронная почта</label>
                <input type="email" className="registerInput" placeholder="Введите электронную почту..." onChange={e=>setEmail(e.target.value)} />
                <label>Пароль</label>
                <input type="password" className="registerInput" placeholder="Введите пароль..." onChange={e=>setPassword(e.target.value)} />
                <button className="registerButton" type="submit">{disabled ? 'Регистрация...' : 'Зарегистрироваться'}</button>
            </form>
            <button className="registerLoginButton">
                <Link className="link" to="/login">Войти</Link>
            </button>
            {error && <span style={{color:"red", marginTop:"10px"}}>Что-то пошло не так</span>}
        </div>
    )
}
