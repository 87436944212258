import "./post.css";
import { Link } from "react-router-dom";
import { imageUrl } from "../../constants/Global";

export default function Post({ post }) {
    const PF = imageUrl + "/api/image/";
    return (
        <div className="post">
            {post.photo && (
                <img className="postImg" src={PF + post.photo} alt={post.title} />
            )}
            <div className="postInfo">
                <div className="postHashtags">
                    {post.hashtags.map((c) => (
                        <span className="postHashtag" key={post.id+c.name}>{c.name}</span>
                    ))}
                </div>
                <Link to={`/post/${post.id}`} className="link">
                    <span className="postTitle">{post.title}</span>
                </Link>
                <hr />
                <div className="postAdditionalInfo">
                    <i className="postEyeIcon fas fa-eye">
                        <span className="postEyeViewers">
                            {" " + post.viewers}
                        </span>
                    </i>
                    <span className="postDate">
                        {new Date(post.createdDate).toLocaleString("ru", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                        })}
                    </span>
                </div>
            </div>
            <p className="postDesc">{post.description}</p>
        </div>
    );
}
