import "./about.css";
import photo from "./../../assets/images/me.jpeg";
import { imageUrl } from "../../constants/Global";

export default function About() {
    document.title = "О Проекте";
    document.querySelector("meta[property='og:title']").setAttribute("content", "О Проекте");
    document.querySelector("meta[property='og:description']").setAttribute("content", "Создатель «НАУКОЗАЦИЯ» Екатерина Косевич, кандидат политических наук, политолог, специалист по Латинской Америке и межамериканской системе. Старший научный сотрудник и доцент двух ведущих профильных научно- исследовательских институтов России (г. Москва)");
    document.querySelector("meta[property='og:image']").setAttribute("content", imageUrl + "/api/image/logo.png");
    document.querySelector("meta[property='og:url']").setAttribute("content", imageUrl + "/about");
    document.getElementsByName("description")[0].content="Создатель «НАУКОЗАЦИЯ» Екатерина Косевич, кандидат политических наук, политолог, специалист по Латинской Америке и межамериканской системе. Старший научный сотрудник и доцент двух ведущих профильных научно- исследовательских институтов России (г. Москва)";
    return (
        <div className="about">
            <p>
                Создатель «НАУКОЗАЦИЯ»
                <strong> Екатерина Косевич</strong>, кандидат политических наук,
                политолог, специалист по Латинской Америке и межамериканской
                системе.
            </p>
            <img src={photo} alt="Фото" />
            <p>
                <a href="https://scholar.google.ru/citations?hl=ru&view_op=list_works&gmla=AJsN-F5jwT575jxRtonI9AgCBrA-7BUy-eKWiGldnNkBfRttJz0gjnc_kiQD-1Eyqr0LEDtjLETOhWIz7-RaSSePWFtUo7ptndFl6RZyV6YgH6fg9M7yseAZNkpRy38uzc_T3JvhZnSg&user=jp5POxYAAAAJ">Google Scholar</a>{"\n"}
                <strong>SPIN РИНЦ:</strong> <a href="https://elibrary.ru/author_profile.asp?authorid=940271">1710-1990</a>{"\n"}
                <strong>ORCID:</strong> <a href="https://orcid.org/0000-0002-8056-8426">0000-0002-8056-8426</a>{"\n"}
                <strong>ResearcherID:</strong> <a href="https://www.webofscience.com/wos/author/rid/Y-3776-2019">Y-3776-2019</a>{"\n"}
                <strong>Scopus AuthorID:</strong> <a href="https://www.scopus.com/authid/detail.uri?partnerID=HzOxMe3b&authorId=57208116089&origin=inward">57208116089</a>
            </p>
            <p>
                Старший научный сотрудник и доцент двух ведущих профильных
                научно- исследовательских институтов России (г. Москва).
            </p>
            <p>
                Руководитель крупного коллективного проекта, который был
                поддержан в рамках конкурса 2022 года на получение грантов РНФ
                по мероприятию «Проведение исследований научными группами под
                руководством молодых ученых» Президентской программы
                исследовательских проектов, реализуемых ведущими учеными, в том
                числе молодыми учеными.
            </p>
            <p>
                Победитель и руководитель ряда исследовательских проектов,
                поддержанных РФФИ, а также руководитель исследовательского
                проекта, поддержанного Факультетом мировой экономики и мировой
                политики НИУ ВШЭ.
            </p>
            <p>
                Автор индивидуальной монографии Е.Ю. Косевич «Мексика в системе
                геополитических координат начала XXI века» (М.; СПб.:
                Нестор-История, 2020 ISBN 978-5-4469-1716-7), которая стала
                победителем Конкурса Российским Фондом Фундаментальных
                Исследований (РФФИ) на издание лучших научных трудов в 2020 г.,
                а также победителем конкурса на назначение персональной надбавки
                ректора НИУ ВШЭ в 2021 г.
            </p>
            <p>
                Автор целого ряда научных статей (по широкому спектру
                специальностей: политология, юридические науки, история и
                экономика) в российских и зарубежных журналах, а также
                аналитических материалов для органов государственной власти.
            </p>
            <p>
                Стала первым автором из России, который опубликовал свое
                исследование в рейтинговых научный журналах: «América Latina en
                la Historia Económica» (Мексика, Q1) и «Latin American
                Perspectives» (США, Q1).
            </p>
            <p>
                Приглашенный лектор зарубежных университетов (Валенсийский
                международный университет и Автономный Университет Мадрида).
            </p>
            <p>
                Консультант российских и зарубежных СМИ по тематике Латинская
                Америка и межамериканская система.
            </p>
        </div>
    );
}
