import "./settings.css"
import Sidebar from "../../components/sidebar/Sidebar"
import { useContext, useState } from "react"
import { Context } from "../../context/Context"
import axios from "axios"
import { imageUrl } from "../../constants/Global"
import imageCompression from 'browser-image-compression';

export default function Settings() {
    const [file, setFile] = useState(null)
    const [username, setUsername] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [success, setSuccess] = useState(false)

    const { user, dispatch } = useContext(Context)
    const PF = imageUrl + "/api/image/"

    const compressionOptions = {
        maxSizeMB: 0.05,
        maxWidthOrHeight: 512,
        useWebWorker: true
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        dispatch({ type: "UPDATE_START" })
        const updatedUser = {
            id: user.id,
            name: username,
            email,
            password
        };
        if (file) {
            try {
                const compressedFile = await imageCompression(file, compressionOptions);
                const data = new FormData();
                const fileName = "avatar" + Date.now() + file.name;
                data.append("name", fileName);
                data.append("image", compressedFile);
                updatedUser.profilePicture = fileName;
                await axios.post("/api/image", data);
            } catch (err) {

            }
        }
        try {
            const res = await axios.put("/api/user/" + user.id, updatedUser);
            setSuccess(true)
            dispatch({ type: "UPDATE_SUCCESS", payload: res.data })
        } catch (err) {
            dispatch({ type: "UPDATE_FAILURE" })
        }
    }
    return (
        <div className="settings">
            <div className="settingsWrapper">
                <div className="settingsTitle">
                    <span className="settingsUpdateTitle">Обновление профиля</span>
                    <span className="settingsDeleteTitle">удалить профиль</span>
                </div>
                <form className="settingsForm" onSubmit={handleSubmit}>
                    <label>Аватар</label>
                    <div className="settingsPP">
                        <img src={file ? URL.createObjectURL(file) : PF + user.profilePicture} alt="Аватар" />
                        <label htmlFor="fileInput">
                            <i className="settingsPPIcon far fa-user-circle"></i>
                        </label>
                        <input type="file" id="fileInput" style={{ display: "none" }} onChange={e => setFile(e.target.files[0])} />
                    </div>
                    <label>Имя пользователя</label>
                    <input type="text" placeholder={user.name} onChange={e => setUsername(e.target.value)} />
                    <label>Электронная почта</label>
                    <input type="email" placeholder={user.email} onChange={e => setEmail(e.target.value)} />
                    <label>Пароль</label>
                    <input type="password" onChange={e => setPassword(e.target.value)} />
                    <button className="settingsSubmit" type="submit">Обновить</button>
                    {success && <span style={{ color: "green", textAlign: "center", marginTop: "20px" }}>Профиль успешно обновлён</span>}
                </form>
            </div>
            <Sidebar />
        </div>
    )
}
