import Post from "../post/Post";
import "./posts.css";

export default function Posts({ posts }) {
    return (
        <div className="posts">
            {posts.map((p) => (
                <Post post={p} key={p.id} />
            ))}
            <div className="post" style={{ height: "0px" }}></div>
            <div className="post" style={{ height: "0px" }}></div>
        </div>
    );
}
