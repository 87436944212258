import "./services.css";
import photo from "./../../assets/images/services.jpg";
import { imageUrl } from "../../constants/Global";

export default function Services() {
    document.title = "Платные услуги";
    document.querySelector("meta[property='og:title']").setAttribute("content", "Платные услуги");
    document.querySelector("meta[property='og:description']").setAttribute("content", "Латинская Америка - как на ладони");
    document.querySelector("meta[property='og:image']").setAttribute("content", imageUrl + "/api/image/logo.png");
    document.querySelector("meta[property='og:url']").setAttribute("content", imageUrl + "/services");
    document.getElementsByName("description")[0].content = "Латинская Америка - как на ладони";
    return (
        <div className="services">
            <p>
                <strong>ЛАТИНСКАЯ АМЕРИКА - КАК НА ЛАДОНИ</strong>
            </p>
            <p>
                - Провожу персональные онлайн-лекции по тематикам Латинская
                Америка и Карибский бассейн, внешняя политика США и России в
                регионе.
            </p>
            <p>
                - Информационно-аналитические услуги российским компаниям по
                вопросам международных отношений, политики и экономики стран
                Латинской Америки и Карибского бассейна.
            </p>
            <p>
                кандидат политических наук,{"\n"}
                <strong>Екатерина Юрьевна Косевич</strong>
            </p>
            <p>
                <strong>Контакты:</strong> naukozaciya@yandex.ru
            </p>
            <img src={photo} alt="Фото" />
        </div>
    );
}
