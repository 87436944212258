import "./sidebar.css";
import logo from "./../../assets/images/logo.svg";
import reward from "./../../assets/images/reward.jpg";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

export default function Sidebar() {
    const [hashtags, setHashtags] = useState([]);

    useEffect(() => {
        const getHashtags = async () => {
            const res = await axios.get("/api/hashtags");
            setHashtags(res.data);
        };
        getHashtags();
    }, []);
    return (
        <div className="sidebar">
            <div className="sidebarItem">
                <p>
                    <strong>«Наукозация»</strong> стал победителем Конкурса
                    лучших научно-популярных работ работников НИУ ВШЭ 2023 г.
                </p>
                <img src={reward} alt="Кубок" />
                <p></p>
            </div>
            <div className="sidebarItem">
                <span className="sidebarTitle">О БЛОГЕ НАУКОЗАЦИЯ</span>
                <p>
                    Блог о том, как заниматься наукой и стать успешным молодым
                    ученым: разбираем успешные и неуспешные примеры, делимся
                    опытом (негативным и позитивным), а также обсуждаем
                    последние новости и события.
                    {"\n\n"}
                    Специализация блога -{" "}
                    <strong>
                        исследования Латинской Америки и Ибероамериканского
                        мира.
                    </strong>
                </p>
                <img src={logo} alt="Логотип" />
                <p></p>
            </div>
            <div className="sidebarItem">
                <span className="sidebarTitle">ПЛАТНЫЕ УСЛУГИ</span>
                <p>
                - Персональные онлайн-лекции по тематикам Латинская Америка и Карибский бассейн.{"\n\n"}
                - Информационно - аналитические услуги российским компаниям по вопросам международных отношений.
                </p>
            </div>
            <div className="sidebarItem">
                <span className="sidebarTitle">РАССКАЖИ НАМ СВОЮ ИСТОРИЮ</span>
                <p>
                <strong>Пришли короткую статью</strong>
                {"\n\n"}
                Объем: 2.000 - 5.000 знаков с пробелами по тематике «Наука»,
                «Научная деятельность», «Преподавание», «Личный опыт
                (позитивный или негативный) работы в науке и образовании».
                {"\n\n"}
                <strong>Контакты:</strong> naukozaciya@yandex.ru
                </p>
            </div>
            <div className="sidebarItem">
                <span className="sidebarTitle">ПОИСК ПО ХЭШТЕГАМ</span>
                <ul className="sidebarList">
                    {hashtags.map((c) => (
                        <Link to={`/?hashtagName=${c.name}`} className="link" key={c.id}>
                            <li className="sidebarListItem">#{c.name}</li>
                        </Link>
                    ))}
                </ul>
            </div>
            <div className="sidebarItem">
                <span className="sidebarTitle">В СОЦИАЛЬНЫХ СЕТЯХ</span>
                <div className="sidebarSocial">
                    {/* <a href="https://www.facebook.com/naukozaciya">
                        <i className="sidebarIcon fab fa-facebook-square"></i>
                    </a>
                    <a href="https://www.instagram.com/naukozaciya">
                        <i className="sidebarIcon fab fa-instagram-square"></i>
                    </a> */}
                    <a href="https://vk.com/club209207289">
                        <i className="sidebarIcon fab fa-vk"></i>
                    </a>
                    <a href="https://t.me/naukozaciya">
                        <i className="sidebarIcon fab fa-telegram-plane"></i>
                    </a>
                </div>
            </div>
        </div>
    );
}
