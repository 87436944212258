import "./header.css";
import headerImage from "./../../assets/images/pexels-pixabay-159711-min.jpg";

export default function Header() {
    return (
        <div className="header">
            <div className="headerTitles">
                <span className="headerTitleSm"></span>
                <span className="headerTitleLg">Наукозация</span>
            </div>
            <img className="headerImg" src={headerImage} alt="Наукозация" />
        </div>
    );
}
