import "./topbar.css"
import { Link } from "react-router-dom";
import { useContext } from "react";
import { Context } from "../../context/Context";
import axios from "axios"
import { imageUrl } from "../../constants/Global";

export default function TopBar() {
    const { user, dispatch } = useContext(Context);
    const PF = imageUrl + "/api/image/"

    const handleLogout = async (e) => {
        try {
            const res = await axios.post("/api/auth/logout")
            dispatch({ type: "LOGOUT" })
        } catch (err) {
        }
    }
    return (
        <div className="top">
            <div className="topLeft">
                {/* <a href="https://www.facebook.com/naukozaciya"><i className="topIcon fab fa-facebook-square"></i></a>
                <a href="https://www.instagram.com/naukozaciya"><i className="topIcon fab fa-instagram-square"></i></a> */}
                <a href="https://vk.com/club209207289"><i className="topIcon fab fa-vk"></i></a>
                <a href="https://t.me/naukozaciya"><i className="topIcon fab fa-telegram-plane"></i></a>
            </div>
            <div className="topCenter">
                <ul className="topList">
                    <li className="topListItem">
                        <Link className="link" to="/">ГЛАВНАЯ</Link>
                    </li>
                    <li className="topListItem">
                        <Link className="link" to="/services">ПЛАТНЫЕ УСЛУГИ</Link>
                    </li>
                    <li className="topListItem">
                        <Link className="link" to="/newAuthor">СТАНЬ АВТОРОМ</Link>
                    </li>
                    <li className="topListItem">
                        <Link className="link" to="/about">О ПРОЕКТЕ</Link>
                    </li>
                    {/* <li className="topListItem">
                        <Link className="link" to="/">КОНТАКТЫ</Link>
                    </li> */}
                    <li className="topListItem">
                        <Link className="link" to="/write">{user?.author === true && "НОВЫЙ ПОСТ"}</Link>
                    </li>
                    <li className="topListItem" onClick={handleLogout}>
                        {user && "ВЫХОД"}
                    </li>
                </ul>
            </div>
            <div className="topRight">
                {user ? (<Link to="/settings"><img className="topImg" src={PF + user.profilePicture} alt="Профиль" /></Link>) : (
                    <ul className="topList">
                        <li className="topListItem">
                            <Link className="link" to="/login">ВОЙТИ</Link>
                        </li>
                        {/* <li className="topListItem">
                            <Link className="link" to="/register">РЕГИСТРАЦИЯ</Link>
                        </li> */}
                    </ul>)}
                {/* <i className="topSearchIcon fas fa-search"></i> */}
            </div>
        </div>
    )
}